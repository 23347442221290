export const hammerStates = [
  {
    id: '01_loop',
    duration: 1700,
    loop: true,
    amountOfFrames: 36,
  },
];

export const compassStates = [
  {
    id: '01_loop',
    duration: 4400,
    loop: true,
    amountOfFrames: 96,
  },
];

export const pacmanStates = [
  {
    id: '01_loop',
    duration: 2200,
    loop: true,
    amountOfFrames: 48,
  },
];

export const plugStates = [
  {
    id: '01_loop',
    duration: 4100,
    loop: true,
    amountOfFrames: 90,
  },
];

export const spaceshipStates = [
  {
    id: '01_Start',
    duration: 2000,
    loop: false,
    amountOfFrames: 44,
    chainToState: 1,
  },
  {
    id: '02_Loop',
    duration: 2000,
    loop: true,
    amountOfFrames: 44,
  },
];

export const heartStates = [
  {
    id: '01_Start',
    duration: 800,
    loop: false,
    amountOfFrames: 16,
    chainToState: 1,
  },
  {
    id: '02_Loop',
    duration: 4300,
    loop: true,
    amountOfFrames: 95,
  },
];

export const boltStates = [
  {
    id: '01_Start',
    duration: 700,
    loop: false,
    amountOfFrames: 17,
  },
  {
    id: '01_Start',
    duration: 700,
    loop: false,
    amountOfFrames: 17,
  },
];
