import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

export const buildJSONLD = (contentfulJoboffer, allContentfulWhatWeOffer) => {
  return {
    '@context': 'http://schema.org',
    '@type': 'JobPosting',
    title: contentfulJoboffer.title,
    description: documentToPlainTextString(
      contentfulJoboffer.jobDescription.json
    ),
    datePosted: contentfulJoboffer.updatedAt || contentfulJoboffer.createdAt,
    responsibilities:
      contentfulJoboffer.jobOfferResponsibilities &&
      contentfulJoboffer.jobOfferResponsibilities.length > 0 &&
      contentfulJoboffer.jobOfferResponsibilities.map(
        el =>
          el.responsibilityDescription &&
          el.responsibilityDescription.responsibilityDescription
      ),
    industry: 'Design, Development',
    educationRequirements:
      contentfulJoboffer.yourSpecifications &&
      contentfulJoboffer.yourSpecifications
        .filter(el => el.specificationType === 'Education')
        .map(el => el.specification),
    experienceRequirements:
      contentfulJoboffer.yourSpecifications &&
      contentfulJoboffer.yourSpecifications
        .filter(el => el.specificationType === 'Experience')
        .map(el => el.specification),
    qualifications:
      contentfulJoboffer.yourSpecifications &&
      contentfulJoboffer.yourSpecifications
        .filter(el => el.specificationType === 'Qualification')
        .map(el => el.specification),
    skills:
      contentfulJoboffer.yourSpecifications &&
      contentfulJoboffer.yourSpecifications
        .filter(el => el.specificationType === 'Skills')
        .map(el => el.specification),
    jobBenefits:
      allContentfulWhatWeOffer &&
      allContentfulWhatWeOffer
        .filter(el => el.specificationType === 'Benefit')
        .map(el => `${el.headline}. ${el.description.description}`),
    incentiveCompensation:
      allContentfulWhatWeOffer &&
      allContentfulWhatWeOffer
        .filter(el => el.specificationType === 'Incentive Compensation')
        .map(el => `${el.headline}. ${el.description.description}`),
    jobLocation: {
      '@type': 'Place',
      address:
        contentfulJoboffer.location === 'Berlin'
          ? {
              '@type': 'PostalAddress',
              streetAddress: 'Zeughofstraße 20',
              addressLocality: 'Berlin',
              addressRegion: 'Berlin',
              postalCode: '10997',
              addressCountry: 'DE',
            }
          : {
              '@type': 'PostalAddress',
              streetAddress: 'Mainzer Landstraße 123',
              addressLocality: 'Frankfurt a.M.',
              addressRegion: 'Hessen',
              postalCode: '60327',
              addressCountry: 'DE',
            },
    },
    hiringOrganization: {
      '@type': 'Organization',
      url: 'https://neugelb.com',
      name: 'Neugelb Studios GmbH',
      logo: 'https://neugelb.com/assets/icons/header/logo-black.svg',
    },
    workHours:
      contentfulJoboffer.employmentType === 'FULL_TIME'
        ? '40 hours per week'
        : null,
    employmentType: contentfulJoboffer.employmentType,
  };
};
