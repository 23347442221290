import * as React from 'react';
import { Heading2 } from '../../helper/Typography';
import { ContentfulOutro } from '../../generated/graphql-types';
import { Text } from '../../helper/Typography';
import { Stopper } from './Stopper';
import { RichTextRenderer } from '../../Renderer/RichTextRenderer';

export interface OutroProps {
  darkTheme: boolean;
  outro: ContentfulOutro;
  fallback: { title: string; subtitle: string };
}

export const Outro = ({ darkTheme, outro, fallback }: OutroProps) => {
  return (
    <>
      <Heading2 darkTheme={darkTheme}>
        {outro && outro.headline ? outro.headline : fallback.title}
      </Heading2>
      <Text.p darkTheme={darkTheme}>
        {outro && outro.text && outro.text.json && outro.text.json.content ? (
          <RichTextRenderer
            content={outro.text.json.content}
          ></RichTextRenderer>
        ) : (
          fallback.subtitle
        )}
      </Text.p>

      {outro &&
        outro.stopper &&
        outro.stopper.text &&
        outro.stopper.text.json &&
        outro.stopper.text.json.content && (
          <Stopper>
            <RichTextRenderer
              content={outro.stopper.text.json.content}
            ></RichTextRenderer>
          </Stopper>
        )}
    </>
  );
};
