import React from 'react';
import { colors } from '../../helper/Variables';
import styled from 'styled-components';

export const Stopper = (children: any) => {
  return <InfoBox>{children.children}</InfoBox>;
};

const InfoBox = styled.div`
  width: 90%;
  height: auto;
  background-color: ${colors.turquoise};
  margin-bottom: 20px;
  padding: 20px;
  line-height: 32px !important;
`;
