import React from 'react';
import styled from 'styled-components';
import { Text } from '../../helper/Typography';
import { SimpleImage } from '../../helper/StyledHelper';

const sortList = items =>
  items.sort((a, b) => {
    if (a.contentfulid < b.contentfulid) {
      return -1;
    } else if (a.contentfulid > b.contentfulid) {
      return 1;
    }
    return 0;
  });

export const OfferIconList = props => {
  return (
    <OfferIconListContainer style={{ alignSelf: 'start' }}>
      {sortList(props.items).map((i, idx) => (
        <IconListItem key={`whatWeOffer-${idx}`}>
          <Icon
            src={`/assets/jobOffers/whatWeOfferIcons/${
              props.darkTheme ? i.imageId : i.imageId + '-white'
            }.svg`}
          />
          <HeadLine darkTheme={props.darkTheme}>{i.headline}</HeadLine>
          <Text.small darkTheme={props.darkTheme}>
            {i.description.description}
          </Text.small>
        </IconListItem>
      ))}
    </OfferIconListContainer>
  );
};

const OfferIconListContainer = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: start;

  @media (max-width: 1023px) {
    display: block;
  }
  @media (max-width: 767px) {
    small {
      line-height: 28px;
    }
  }
`;
const IconListItem = styled.div`
  flex: 1;
  margin-bottom: 60px;
  min-width: 100%;
  @media (min-width: 1024px) {
    min-width: calc(50% - 40px);
    max-width: 50%;
    padding-right: 40px;
  }
`;
const Icon = styled(SimpleImage)`
  max-width: 80px;
  margin-left: -15px;
  margin-bottom: 16px;
`;
const HeadLine = styled(Text.p)`
  /*min-height: calc(32px * 1);*/
  margin-bottom: 8px;
  @media (max-width: 767px) {
    margin-bottom: 16px;
  }
`;
