// BPS:
// 2 - mobile1: 0 - 320
// 2 - mobile2: 321 - 479
// 3 - mobile3: 480 - 767
// 4 - tablet1: 768 - 1023
// 5 - tablet2: 1024 - 1279
// 6 - desktop1: 1280 - 1599
// 7 - desktop2: 1600 - 1919
// 8 - desktop3: 1920 - 2239
// 9 - desktop4: 2240 - x

export const sectionHeight = {
  //bps:            [1, 2, 3, 4, 5, 6, 7, 8, 9]
  stage: [7, 7, 7, 11, 8, 8, 8, 8, 8],
  fullWidthStage: [4, 4, 4, 0, 0, 0, 0, 0, 0],
  /*responsibilities: [9, 9, 9, 8, 8, 5, 5, 5, 5],*/
  banner: [6, 6, 6, 4, 4, 4, 4, 4, 4],
  /*specs:            [12, 10, 10, 8, 8, 7, 7, 7, 7],*/
  ourSpecs: [31, 26, 25, 15, 15, 11, 11, 10, 10],
  specsExtended: [34, 29, 28, 18, 15, 17, 17, 17, 17],
  form: [14, 12, 11, 11, 10, 8, 8, 8, 8],
  allJobs: [8, 8, 8, 7, 7, 7, 7, 7, 7],
};

export const page = {
  greeting: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [3, 3, 3, 6, 3, 3, 3, 3, 3],
    left: [1, 1, 1, 2, 1, 1, 2, 2, 4],
    boxWidth: [3, 3, 4, 3, 3, 3, 3, 3, 3],
    boxHeight: [3, 3, 3, 4, 4, 4, 4, 4, 4],
  },
  titleImage: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [0, 0, 0, 1, 1, 1, 1, 1, 1],
    left: [1, 1, 2, 3, 6, 6, 7, 7, 8],
    boxWidth: [3, 3, 3, 3, 3, 3, 3, 3, 3],
    boxHeight: [4, 4, 4, 4, 4, 4, 4, 4, 4],
  },
  description: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [6, 6, 6, 9, 6, 7, 7, 7, 7],
    left: [1, 1, 1, 2, 3, 3, 4, 5, 6],
    boxWidth: [3, 3, 4, 4, 4, 4, 4, 4, 4],
    boxHeight: [3, 2, 2, 2, 2, 2, 2, 2, 2],
  },
  responsibilities: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [1, 1, 1, 1, 1, 1, 1, 1, 1],
    left: [1, 1, 1, 2, 3, 3, 4, 5, 6],
    boxWidth: [3, 3, 4, 4, 4, 4, 4, 4, 4],
    boxHeight: [5, 5, 5, 5, 5, 5, 5, 5, 5],
  },
  yourSpecs: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [2, 2, 2, 2, 2, 2, 2, 2, 2],
    left: [1, 1, 1, 2, 3, 3, 4, 5, 6],
    boxWidth: [3, 3, 4, 4, 4, 4, 4, 4, 4],
    boxHeight: [8, 7, 7, 6, 5, 2, 2, 2, 2],
  },
  ourSpecsHeadline: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [1, 2, 2, 2, 2, 2, 2, 2, 2],
    left: [1, 1, 1, 2, 2, 2, 3, 4, 5],
    boxWidth: [3, 3, 4, 4, 4, 4, 4, 4, 4],
  },
  ourSpecs: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [3, 3, 3, 3, 3, 3, 3, 3, 3],
    left: [1, 1, 1, 2, 2, 2, 3, 4, 5],
    boxWidth: [3, 3, 4, 4, 6, 6, 6, 6, 6],
  },
};

export const patternsLayout = {
  p3: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [4, 4, 4, 5, 5, 4, 4, 4, 4],
    left: [1, 1, 1, 1, 1, 1, 2, 3, 4],
  },
  p4: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [1, 1, 1, 1, 1, 1, 1, 1, 1],
    left: [3, 3, 4, 6, 7, 7, 8, 9, 10],
  },
  p5: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [2, 2, 2, 2, 2, 2, 2, 2, 2],
    left: [0, 0, 0, 6, 8, 8, 9, 10, 11],
  },
  p6: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [4, 4, 4, 4, 4, 4, 4, 4, 4],
    left: [1, 1, 1, 1, 1, 1, 1, 1, 1],
  },
  p7: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [1, 1, 1, 1, 1, 1, 1, 1, 1],
    left: [1, 1, 1, 1, 1, 1, 1, 1, 1],
  },
  p8: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [3, 3, 3, 3, 3, 3, 3, 3, 3],
    left: [3, 3, 4, 8, 8, 8, 9, 10, 11],
  },
  p9: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [4, 4, 4, 4, 4, 4, 4, 4, 4],
    left: [3, 3, 4, 9, 9, 9, 10, 11, 12],
  },
  p10: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [7, 7, 7, 7, 7, 7, 7, 7, 7],
    left: [3, 3, 4, 6, 9, 9, 10, 11, 12],
  },
  p11: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [1, 1, 1, 1, 1, 1, 1, 1, 1],
    left: [3, 3, 4, 7, 7, 7, 8, 9, 10],
  },
  p12: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [9, 9, 9, 10, 14, 10, 9, 9, 9],
    left: [1, 1, 1, 1, 1, 1, 1, 1, 1],
  },
  p13: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [10, 10, 10, 11, 15, 11, 10, 10, 10],
    left: [2, 2, 2, 2, 2, 2, 2, 2, 2],
  },
  // [ 6, 6, 6, 5, 5, 5, 5, 5, 5]
  p14: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [6, 6, 6, 5, 5, 5, 5, 5, 5],
    left: [2, 2, 2, 3, 5, 5, 5, 6, 7],
    boxWidth: [3, 3, 4, 2, 2, 2, 2, 2, 2],
  },
  p15: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [7, 7, 7, 6, 6, 6, 6, 6, 6],
    left: [1, 1, 1, 1, 1, 1, 1, 1, 1],
  },
  p16: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [7, 7, 7, 6, 6, 6, 6, 6, 6],
    left: [3, 3, 3, 3, 5, 5, 5, 6, 7],
  },
  p17: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [7, 7, 7, 6, 6, 6, 6, 6, 6],
    left: [4, 4, 4, 4, 6, 6, 6, 7, 8],
  },
};

export const form = {
  heading: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [2, 2, 2, 2, 2, 2, 2, 2, 2],
    left: [1, 1, 1, 2, 3, 3, 4, 5, 6],
    boxWidth: [3, 3, 4, 4, 4, 4, 4, 4, 4],
    boxHeight: [2, 2, 2, 2, 2, 2, 2, 2, 2],
  },
};

export const hammerLayout = {
  //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
  top: [1, 1, 1, 4, 4, 4, 4, 4, 4],
  left: [3, 3, 4, 4, 7, 7, 8, 8, 9],
};

export const plugLayout = {
  //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
  top: [2, 2, 2, 4, 4, 4, 4, 4, 4],
  left: [2, 2, 2, 3, 6, 6, 7, 7, 8],
};

export const compassLayout = {
  //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
  top: [1, 1, 1, 4, 4, 4, 4, 4, 4],
  left: [1, 1, 1, 4, 7, 7, 8, 8, 9],
};

export const pacmanLayout = {
  //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
  top: [2, 2, 2, 3, 3, 3, 3, 3, 3],
  left: [1, 1, 1, 3, 6, 6, 7, 7, 8],
};

export const spaceshipLayout = {
  //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
  top: [1, 1, 1, 1, 1, 1, 1, 1, 1],
  left: [1, 1, 1, 6, 7, 6, 7, 8, 9],
};
