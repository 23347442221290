import { GridBox } from '../../../components/Grid';
import React from 'react';
import { SimpleImage } from '../../../helper/StyledHelper';
import { stages } from './layouts';

const StagePatternRenderer = props => {
  let gridBoxes = [];
  for (let i = 0; i < 5; i++) {
    gridBoxes.push({
      id: i + 1,
      layout: stages[props.category][`p${i + 1}`],
    });
  }
  return gridBoxes.map(box => (
    <GridBox
      key={`pattern-${box.id}`}
      layout={box.layout}
      className={
        box.id >= 3 ? 'showAbove-desktop3' : box.id === 2 && 'showAbove-mobile3'
      }
    >
      <SimpleImage
        src={`/assets/jobOffers/_patterns/${props.category}/pattern-0${box.id}.svg`}
      />
    </GridBox>
  ));
};

export default StagePatternRenderer;
