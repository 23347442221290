// BPS:
// 2 - mobile1: 0 - 320
// 2 - mobile2: 321 - 479
// 3 - mobile3: 480 - 767
// 4 - tablet1: 768 - 1023
// 5 - tablet2: 1024 - 1279
// 6 - desktop1: 1280 - 1599
// 7 - desktop2: 1600 - 1919
// 8 - desktop3: 1920 - 2239
// 9 - desktop4: 2240 - x

export const stages = {
  design: {
    p1: {
      //bps:  [1, 2, 3, 4, 5, 6, 7, 8, 9]
      top: [1, 1, 1, 4, 4, 4, 4, 4, 4],
      left: [2, 2, 3, 3, 6, 6, 7, 7, 8],
    },
    p2: {
      top: [0, 0, 0, 5, 5, 5, 5, 5, 5],
      left: [0, 0, 0, 5, 8, 8, 9, 9, 10],
    },
    p3: {
      top: [0, 0, 0, 0, 0, 0, 0, 4, 4],
      left: [0, 0, 0, 0, 0, 0, 0, 1, 1],
    },
    p4: {
      top: [0, 0, 0, 0, 0, 0, 0, 7, 7],
      left: [0, 0, 0, 0, 0, 0, 0, 3, 3],
    },
    p5: {
      top: [0, 0, 0, 0, 0, 0, 0, 7, 7],
      left: [0, 0, 0, 0, 0, 0, 0, 12, 14],
    },
  },
  development: {
    p1: {
      //bps:  [1, 2, 3, 4, 5, 6, 7, 8, 9]
      top: [1, 1, 1, 4, 4, 4, 4, 4, 4],
      left: [1, 1, 1, 5, 8, 8, 9, 9, 10],
    },
    p2: {
      top: [0, 0, 0, 5, 5, 5, 5, 5, 5],
      left: [0, 0, 0, 5, 8, 8, 9, 9, 10],
    },
    p3: {
      top: [0, 0, 0, 0, 0, 0, 0, 4, 4],
      left: [0, 0, 0, 0, 0, 0, 0, 1, 1],
    },
    p4: {
      top: [0, 0, 0, 0, 0, 0, 0, 7, 7],
      left: [0, 0, 0, 0, 0, 0, 0, 3, 3],
    },
    p5: {
      top: [0, 0, 0, 0, 0, 0, 0, 7, 7],
      left: [0, 0, 0, 0, 0, 0, 0, 12, 14],
    },
  },
  human_resources: {
    p1: {
      //bps:  [1, 2, 3, 4, 5, 6, 7, 8, 9]
      top: [1, 1, 1, 4, 4, 4, 4, 4, 4],
      left: [3, 3, 4, 5, 8, 8, 9, 9, 10],
    },
    p2: {
      top: [0, 0, 0, 5, 5, 5, 5, 5, 5],
      left: [0, 0, 0, 5, 8, 8, 9, 9, 10],
    },
    p3: {
      top: [0, 0, 0, 0, 0, 0, 0, 4, 4],
      left: [0, 0, 0, 0, 0, 0, 0, 1, 1],
    },
    p4: {
      top: [0, 0, 0, 0, 0, 0, 0, 7, 7],
      left: [0, 0, 0, 0, 0, 0, 0, 3, 3],
    },
    p5: {
      top: [0, 0, 0, 0, 0, 0, 0, 7, 7],
      left: [0, 0, 0, 0, 0, 0, 0, 12, 14],
    },
  },
  office_management: {
    p1: {
      //bps:  [1, 2, 3, 4, 5, 6, 7, 8, 9]
      top: [1, 1, 1, 4, 4, 4, 4, 4, 4],
      left: [3, 3, 4, 5, 8, 8, 9, 9, 10],
    },
    p2: {
      top: [0, 0, 0, 5, 5, 5, 5, 5, 5],
      left: [0, 0, 0, 5, 8, 8, 9, 9, 10],
    },
    p3: {
      top: [0, 0, 0, 0, 0, 0, 0, 4, 4],
      left: [0, 0, 0, 0, 0, 0, 0, 1, 1],
    },
    p4: {
      top: [0, 0, 0, 0, 0, 0, 0, 7, 7],
      left: [0, 0, 0, 0, 0, 0, 0, 3, 3],
    },
    p5: {
      top: [0, 0, 0, 0, 0, 0, 0, 7, 7],
      left: [0, 0, 0, 0, 0, 0, 0, 12, 14],
    },
  },
  project_management: {
    p1: {
      //bps:  [1, 2, 3, 4, 5, 6, 7, 8, 9]
      top: [1, 1, 1, 4, 4, 4, 4, 4, 4],
      left: [2, 2, 2, 3, 6, 6, 7, 7, 8],
    },
    p2: {
      top: [5, 5, 5, 5, 5, 5, 5, 5, 5],
      left: [3, 3, 4, 5, 8, 8, 9, 9, 10],
    },
    p3: {
      top: [0, 0, 0, 0, 0, 0, 0, 5, 4],
      left: [0, 0, 0, 0, 0, 0, 0, 6, 1],
    },
    p4: {
      top: [0, 0, 0, 0, 0, 0, 0, 5, 7],
      left: [0, 0, 0, 0, 0, 0, 0, 6, 3],
    },
    p5: {
      top: [0, 0, 0, 0, 0, 0, 0, 5, 7],
      left: [0, 0, 0, 0, 0, 0, 0, 6, 14],
    },
  },
};
