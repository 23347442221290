import React from 'react';
import {
  ContentfulJoboffer,
  ContentfulWhatWeOfferEdge,
  ContentfulWhatWeOffer,
  ContentfulYourSpecifications,
  ContentfulJobOfferResponsibilty,
} from '../../generated/graphql-types';
import VisibilitySensor from 'react-visibility-sensor';
import styled from 'styled-components';

import {
  Header,
  RandomProfile,
  Image,
  Sprite,
  PageLayout,
  Teaser,
  Overlay,
} from '../../components/index';
import { GridBox, GridSection, FullWidthElement } from '../../components/Grid';
import {
  SimpleImage,
  Button,
  SocialIconsWrapper,
} from '../../helper/StyledHelper';
import {
  patternsLayout,
  sectionHeight,
  page,
  form,
  spaceshipLayout,
} from './layouts';
import { Heading2, List, ListItem, Text } from '../../helper/Typography';
import {
  toSlug,
  transformLanguageString,
  getJobOfferCategory,
  getJobOfferLocation,
  getUrlFromId,
} from '../../helper/helperServices';
import StagePatternRenderer from './StagePatternRenderer/StagePatternRenderer';
import SocialIcons from '../../components/SocialIcons/SocialIcons';
import { spaceshipStates } from './animations';
import { graphql } from 'gatsby';
import ContactForm from '../../components/Forms/ContactForm';
import {
  EmploymentType,
  JobOfferCategory,
  JobOfferLocation,
  LocationObject,
} from '../../components/component-types';
import { buildJSONLD } from './jsonGenerator';
import Helmet from 'react-helmet';
import PageEnhancer from '../../components/PageEnhancer';
import { SeoDataComponent } from '@lws/react-components';
import { mapMetaObject } from '../../helper/helperServices';
import { bps } from '../../helper/Variables';
import { OfferIconList } from '../../components/OfferIconList/OfferIconList';
import { Outro } from '../../components/Outro/Outro';
import { ContentfulRichTextRenderer } from '@lws/react-components';

interface EntryPageProps {
  location: LocationObject;
  data: {
    contentfulJoboffer: ContentfulJoboffer;
    allContentfulWhatWeOffer: ContentfulWhatWeOfferEdge;
  };
  pageContext: {
    url: string;
    locale: string;
    node_locale: string;
    generalTexts: any;
    routeTexts: any;
  };
}

const animToCategory = {
  design: 'hammer',
  development: 'pacman',
  project_management: 'compass',
  human_resources: 'plug',
};

class JobOffer extends PageEnhancer {
  constructor(props: EntryPageProps) {
    super(props);
    this.state = {
      hammer: undefined,
      pacman: undefined,
      compass: undefined,
      plug: undefined,
      spaceship: undefined,
      darkTheme: false,
      overlay: {
        formState: undefined,
        requestType: undefined,
      },
      documentsLength: 0,
      activeBreakpoint: undefined,
    };
  }
  refsCollection = {};

  componentDidMount() {
    this.setInitialAnimationsState([
      animToCategory[
        getJobOfferCategory(this.props.data.contentfulJoboffer.category)
      ],
    ]);
    this.callSuperComponentDidMountMethods();
  }

  toggleOverlay(formState: any, requestType: string) {
    this.setState({
      overlay: {
        formState,
        requestType,
      },
    });
  }

  getDocumentsLength = (documentsLength: number, offset?: number) => {
    const documentsLengthWithOffset = offset
      ? documentsLength + offset
      : documentsLength;
    return this.setState({
      ...this.state,
      documentsLengthWithOffset,
    });
  };

  setGridHeight = (
    heightArray: number[],
    refKeys: string[],
    customOffset: number | number[] = 0
  ) => {
    const currentBP = this.getInitalBp();
    const currentBPIdx = bps.indexOf(currentBP);

    return heightArray.map((e, idx) => {
      if (idx === currentBPIdx) {
        const clientHeightArray: number[] = refKeys.map(
          (key: string) => this.refsCollection[key].clientHeight
        );

        const clientHeight = clientHeightArray.reduce((acc, val) => {
          return acc + val;
        });

        const offset =
          Math.ceil(clientHeight / currentBP.baseUnit) -
            clientHeight / currentBP.baseUnit <
          0.5
            ? 1
            : 0;
        const gridHeight = Math.ceil(clientHeight / currentBP.baseUnit);
        return Array.isArray(customOffset)
          ? gridHeight + offset + customOffset[idx]
          : gridHeight + offset + customOffset;
      }
      return Array.isArray(customOffset)
        ? e + customOffset[idx]
        : e + customOffset;
    });
  };

  getFormHeight = (sectionHeight: number[], documentsListLength: number) =>
    sectionHeight.map(
      (bp, idx) => bp + Math.floor(documentsListLength / (idx < 3 ? 2 : 3))
    );

  render() {
    const {
      title,
      internalPosition,
      location,
      category,
      jobOfferResponsibilities,
      yourSpecifications,
      employmentType,
      outro,
      customQuestions,
      jobDescription,
      internalJobId,
    } = this.props.data.contentfulJoboffer;
    const { generalTexts, routeTexts, locale } = this.props.pageContext;
    const sluggedLocation = toSlug(location);
    const ourSpecifications = generateOurSpecifications(
      this.props.data.allContentfulWhatWeOffer.nodes,
      category,
      location,
      employmentType
    );
    const { pathname } = this.props.location;

    return (
      <PageLayout
        userLanguage={locale}
        generalText={generalTexts}
        darkTheme={this.state.darkTheme}
      >
        <JobDetailContainer
          className={this.state.darkTheme ? 'App Dark' : 'App Light'}
        >
          <Header
            darkTheme={this.state.darkTheme}
            lang={locale}
            pathname={this.props.location.pathname}
            generalTexts={generalTexts}
          />
          <SeoDataComponent
            data={
              mapMetaObject({
                ...routeTexts.meta,
                title: `${title} in ${location} ${routeTexts.meta.title}`,
                openGraphTitle: `${routeTexts.meta.openGraphTitle} ${title}`,
              }).data
            }
          />
          <GridSection
            gridRows={sectionHeight.fullWidthStage}
            className={'hideAbove-mobile3'}
          >
            <FullWidthElement>
              <Image
                imageFolderUrl={`/assets/jobOffers/${sluggedLocation}/${getJobOfferCategory(
                  category
                )}/img`}
                alt={`${category} in ${location}`}
              />
            </FullWidthElement>
          </GridSection>

          <GridSection
            gridRows={sectionHeight.stage}
            className={'translatedToTop'}
          >
            <GridBox layout={page.greeting} style={{ alignContent: 'start' }}>
              <Text.pGrey>
                {location === 'Berlin'
                  ? routeTexts.greeting.berlin
                  : routeTexts.greeting.ffm}
              </Text.pGrey>
              <Heading2 style={{ marginBottom: '40px' }} as={'h1'}>
                {title}
              </Heading2>
              <FlexBox>
                <Button
                  onClick={() => {
                    this.refsCollection['contactRef'].scrollIntoView({
                      behavior: 'smooth',
                    });
                  }}
                >
                  {routeTexts.greeting.applyButtonText}
                </Button>
                <SocialIconsWrapper>
                  <SocialIcons
                    isSharing={true}
                    dark={this.state.darkTheme}
                    locale={locale}
                    pathname={pathname}
                  />
                </SocialIconsWrapper>
              </FlexBox>
            </GridBox>

            <GridBox layout={page.titleImage}>
              <Image
                className={'showAbove-mobile3'}
                imageFolderUrl={`/assets/jobOffers/${sluggedLocation}/${getJobOfferCategory(
                  category
                )}/img`}
                alt={`${category} in ${location}`}
              />
            </GridBox>

            {(typeof this.state.compass !== 'undefined' ||
              typeof this.state.hammer !== 'undefined' ||
              typeof this.state.plug !== 'undefined' ||
              typeof this.state.pacman !== 'undefined') && (
              <Sprite
                layout={
                  require('./layouts')[
                    `${animToCategory[getJobOfferCategory(category)]}Layout`
                  ]
                }
                name={animToCategory[getJobOfferCategory(category)]}
                animationState={
                  this.state[animToCategory[getJobOfferCategory(category)]]
                }
                setAnimationState={this.setAnimationState}
                isActive={true}
                isSticky={false}
                animationsDefinitions={
                  require('./animations')[
                    `${animToCategory[getJobOfferCategory(category)]}States`
                  ]
                }
              />
            )}

            {/* ===== Dynamic patterns ===== */}
            <StagePatternRenderer category={getJobOfferCategory(category)} />
            {/* ===== Dynamic patterns ===== */}

            <GridBox
              layout={{
                ...page.description,
                boxHeight: this.setGridHeight(page.description['boxHeight'], [
                  'jobDesc',
                ]),
              }}
            >
              <div
                id="jobDesc"
                ref={ref => (this.refsCollection['jobDesc'] = ref)}
              >
                <Text.p>
                  <ContentfulRichTextRenderer content={jobDescription.json} />
                </Text.p>
              </div>
            </GridBox>
          </GridSection>

          {jobOfferResponsibilities && (
            <GridSection
              gridRows={this.setGridHeight(
                new Array(9)
                  .fill(jobOfferResponsibilities.length)
                  .map((el, idx) =>
                    idx < 5
                      ? 3 + jobOfferResponsibilities.length
                      : 1 + jobOfferResponsibilities.length
                  ),
                ['jobOfferRespRef']
              )}
            >
              <GridBox
                layout={page.responsibilities}
                style={{ alignContent: 'start' }}
              >
                <div
                  id="jobOfferRespRef"
                  ref={ref => (this.refsCollection['jobOfferRespRef'] = ref)}
                >
                  <Heading2>
                    {this.props.data.contentfulJoboffer.responsibilitiesHeadline
                      ? this.props.data.contentfulJoboffer
                          .responsibilitiesHeadline
                      : routeTexts.responsibilities.title}
                  </Heading2>
                  <List>
                    {jobOfferResponsibilities.map(
                      (res: ContentfulJobOfferResponsibilty, i: number) => (
                        <ListItem key={`res-${i}`}>
                          {res &&
                            res.responsibilityDescription &&
                            res.responsibilityDescription
                              .responsibilityDescription}
                        </ListItem>
                      )
                    )}
                  </List>
                </div>
              </GridBox>
              <GridBox
                layout={patternsLayout.p3}
                className={'showAbove-mobile3'}
              >
                <SimpleImage
                  src={`/assets/jobOffers/_patterns/pattern-01.svg`}
                />
              </GridBox>
            </GridSection>
          )}
          <GridSection gridRows={sectionHeight.banner}>
            <GridBox layout={patternsLayout.p4} translateTop={-0.5}>
              <SimpleImage src={`/assets/jobOffers/_patterns/pattern-02.svg`} />
            </GridBox>
            <FullWidthElement>
              <Image
                imageFolderUrl={`/assets/jobOffers/${sluggedLocation}/${getJobOfferCategory(
                  category
                )}/banner`}
                alt={`${category} in ${location}`}
              />
            </FullWidthElement>
          </GridSection>
          {yourSpecifications && (
            <GridSection
              gridRows={this.setGridHeight(
                new Array(9)
                  .fill(yourSpecifications.length)
                  .map((el, idx) =>
                    idx < 5
                      ? 4 + yourSpecifications.length
                      : 1 + yourSpecifications.length
                  ),
                ['yourSpecs'],
                [1, 0, 0, 1, 1, 0, 0, 0, 0]
              )}
            >
              <GridBox layout={page.yourSpecs}>
                <div
                  id="yourSpecs"
                  ref={ref => (this.refsCollection['yourSpecs'] = ref)}
                >
                  <Heading2 darkTheme={this.state.darkTheme}>
                    {this.props.data.contentfulJoboffer.specificationsHeadline
                      ? this.props.data.contentfulJoboffer
                          .specificationsHeadline
                      : category === 'office_management'
                      ? routeTexts.yourSpecs.titleofficemanager
                      : routeTexts.yourSpecs.titleOther}
                  </Heading2>

                  <List>
                    {yourSpecifications.map(
                      (spec: ContentfulYourSpecifications, i: number) => {
                        return (
                          <ListItem
                            darkTheme={this.state.darkTheme}
                            key={`yourSpec-${i}`}
                          >
                            {spec.specificationLong &&
                            spec.specificationLong.specificationLong
                              ? spec.specificationLong.specificationLong
                              : spec.specification}
                          </ListItem>
                        );
                      }
                    )}
                  </List>
                </div>
              </GridBox>
              <GridBox
                layout={patternsLayout.p5}
                className={'showAbove-mobile3'}
              >
                <SimpleImage
                  src={`/assets/jobOffers/_patterns/pattern-03.svg`}
                />
              </GridBox>
              <GridBox
                layout={patternsLayout.p6}
                className={'showAbove-mobile3'}
              >
                <SimpleImage
                  src={`/assets/jobOffers/_patterns/pattern-04.svg`}
                />
              </GridBox>
            </GridSection>
          )}
          <VisibilitySensor
            partialVisibility={true}
            minTopValue={700}
            offset={{ top: 500 }}
            onChange={isVisible => {
              this.setState({ darkTheme: isVisible });
            }}
          >
            <GridSection
              gridRows={this.setGridHeight(
                sectionHeight.ourSpecs,
                ['ourSpecsRef'],
                ourSpecifications.length % 2 === 0
                  ? [2, 2, 2, 2, 3, 2, 2, 2, 2]
                  : [2, 2, 2, 2, 3, 1, 1, 2, 2]
              )}
            >
              <GridBox
                layout={patternsLayout.p7}
                translateTop={-0.5}
                className={'showAbove-tablet2'}
              >
                <SimpleImage
                  src={`/assets/jobOffers/_patterns/pattern-05.svg`}
                />
              </GridBox>
              <GridBox
                layout={patternsLayout.p8}
                className={'showAbove-tablet2'}
              >
                <SimpleImage
                  src={`/assets/jobOffers/_patterns/pattern-06.svg`}
                />
              </GridBox>
              <GridBox
                layout={patternsLayout.p9}
                className={'showAbove-tablet2'}
              >
                <SimpleImage
                  src={`/assets/jobOffers/_patterns/pattern-07.svg`}
                />
              </GridBox>
              <GridBox
                layout={patternsLayout.p10}
                className={'showAbove-mobile3'}
              >
                <SimpleImage
                  src={`/assets/jobOffers/_patterns/pattern-08.svg`}
                />
              </GridBox>
              <GridBox
                layout={page.ourSpecsHeadline}
                style={{ alignSelf: 'start' }}
              >
                <Heading2 darkTheme={this.state.darkTheme}>
                  {category === 'office_management'
                    ? routeTexts.ourSpecs.titleofficemanager
                    : routeTexts.ourSpecs.titleOther}
                </Heading2>
              </GridBox>
              <GridBox layout={page.ourSpecs} style={{ alignSelf: 'start' }}>
                <div
                  id="ourSpecsRef"
                  ref={ref => (this.refsCollection['ourSpecsRef'] = ref)}
                >
                  <OfferIconList
                    darkTheme={this.state.darkTheme}
                    items={ourSpecifications}
                  />
                </div>
              </GridBox>
            </GridSection>
          </VisibilitySensor>
          <ContactSection>
            <GridSection
              gridRows={this.setGridHeight(
                this.getFormHeight(
                  sectionHeight.form,
                  this.state.documentsLength
                ),
                ['contactRef', 'outroRef'],
                [1, 1, 1, 1, 1, 0, 1, 1, 1]
              )}
            >
              <VisibilitySensor
                onChange={isVisible =>
                  isVisible &&
                  !this.state.spaceship &&
                  this.setAnimationState('spaceship', 0)
                }
              >
                <Sprite
                  layout={spaceshipLayout}
                  name={'spaceship'}
                  animationState={this.state.spaceship}
                  setAnimationState={this.setAnimationState}
                  isActive={true}
                  isSticky={false}
                  animationsDefinitions={spaceshipStates}
                />
              </VisibilitySensor>
              <GridBox layout={form.heading}>
                <div
                  id="outroRef"
                  ref={ref => (this.refsCollection['outroRef'] = ref)}
                >
                  <Outro
                    darkTheme={this.state.darkTheme}
                    outro={outro}
                    fallback={{
                      title: routeTexts.form.title,
                      subtitle: routeTexts.form.subtitle,
                    }}
                  />
                </div>
                <div
                  id="contactRef"
                  ref={ref => (this.refsCollection['contactRef'] = ref)}
                >
                  <ContactForm
                    utmsStr={this.props.location.search}
                    location={getJobOfferLocation(location)}
                    position={title}
                    internalPosition={internalPosition}
                    lang={transformLanguageString(locale)}
                    contactForm={generalTexts.contactForm}
                    requestType={'application'}
                    toggleOverlay={(formState: Object, requestType: string) =>
                      this.toggleOverlay(formState, requestType)
                    }
                    getDocumentsLength={this.getDocumentsLength}
                    darkTheme={this.state.darkTheme}
                    customQuestions={customQuestions}
                    internalJobId={internalJobId}
                    formErrors={routeTexts.form.formErrors}
                  />
                </div>
              </GridBox>
              <GridBox
                layout={patternsLayout.p11}
                translateTop={-1}
                className={'showAbove-mobile3'}
              >
                <SimpleImage
                  src={`/assets/jobOffers/_patterns/pattern-09.svg`}
                />
              </GridBox>
              <GridBox
                layout={patternsLayout.p12}
                className={'showAbove-tablet1'}
              >
                <SimpleImage
                  src={`/assets/jobOffers/_patterns/pattern-10.svg`}
                />
              </GridBox>
              <GridBox
                layout={patternsLayout.p13}
                className={'showAbove-tablet1'}
              >
                <SimpleImage
                  src={`/assets/jobOffers/_patterns/pattern-11.svg`}
                />
              </GridBox>
            </GridSection>
          </ContactSection>
          <GridSection gridRows={sectionHeight.allJobs}>
            <GridBox layout={form.heading}>
              <Teaser
                label={routeTexts.allJobs.label}
                category={category}
                title={routeTexts.allJobs.title}
                texts={title + routeTexts.allJobs.texts}
                button={routeTexts.allJobs.button}
                linkTo={`${getUrlFromId(locale, 'jobs')}#job-offers`}
                column={true}
                darkTheme={this.state.darkTheme}
              />
            </GridBox>
            <GridBox layout={patternsLayout.p14}>
              <SimpleImage src={`/assets/jobOffers/_patterns/pattern-12.svg`} />
            </GridBox>
            <GridBox layout={patternsLayout.p15}>
              <SimpleImage src={`/assets/jobOffers/_patterns/pattern-13.svg`} />
            </GridBox>
            <GridBox layout={patternsLayout.p16}>
              <SimpleImage src={`/assets/jobOffers/_patterns/pattern-14.svg`} />
            </GridBox>
            <GridBox layout={patternsLayout.p17}>
              <SimpleImage src={`/assets/jobOffers/_patterns/pattern-15.svg`} />
            </GridBox>
          </GridSection>
          <VisibilitySensor
            partialVisibility={true}
            minTopValue={150}
            onChange={isVisible => {
              this.setState({ darkTheme: isVisible });
            }}
          >
            <RandomProfile
              userLanguage={locale}
              darkTheme={this.state.darkTheme}
              category={category}
            />
          </VisibilitySensor>
          <Overlay
            currentState={this.state.overlay.formState}
            requestType={this.state.overlay.requestType}
            contactForm={generalTexts.contactForm}
            toggleOverlay={(formState: any, requestType: string) =>
              this.toggleOverlay(formState, requestType)
            }
          />
          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify(
                buildJSONLD(
                  this.props.data.contentfulJoboffer,
                  ourSpecifications
                )
              )}
            </script>
          </Helmet>
        </JobDetailContainer>
      </PageLayout>
    );
  }
}
export default JobOffer;

const JobDetailContainer = styled.main`
  .translatedToTop {
    z-index: 10;
    @media (max-width: 767px) {
      transform: translateY(-112px);
    }
  }
`;

const ContactSection = styled.div`
  @media (max-width: 320px) {
    max-width: 300px;
    margin: 0 auto;
  }
  @media (max-width: 479px) {
    max-width: 336px;
    margin: 0 auto;
  }
`;

const FlexBox = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
`;

// filter out the specs associated with category, location & employment type
const generateOurSpecifications = (
  whatWeOffer: [ContentfulWhatWeOffer],
  category: JobOfferCategory,
  location: JobOfferLocation,
  employmentType: EmploymentType
) => {
  const fullTimeOffers = whatWeOffer.filter(
    (ourOffer: ContentfulWhatWeOffer) => {
      if (ourOffer && ourOffer.category) {
        return (
          (ourOffer.category.replace(/\s/g, '_').toLowerCase() === 'general' ||
            ourOffer.category.replace(/\s/g, '_').toLowerCase() ===
              category.replace(/\s/g, '_').toLowerCase()) &&
          (ourOffer.location === location ||
            ourOffer.location === 'Berlin und Frankfurt am Main')
        );
      }
    }
  );
  if (employmentType === 'FULL_TIME' || employmentType === 'PART_TIME') {
    // if the employment type is FULL or PART_TIME return the mappings without the one for intern
    return fullTimeOffers.filter(i => i.employmentType !== 'INTERN');
  }

  const idsToBeRemoved: any[] = [];
  fullTimeOffers
    .filter(i => i.employmentType === 'INTERN')
    .forEach(i => idsToBeRemoved.push(i.id));

  return fullTimeOffers.filter(
    el => idsToBeRemoved.includes(el.id) && el.employmentType !== 'INTERN'
  );
};

export const query = graphql`
  query($url: String, $node_locale: String) {
    contentfulJoboffer(url: { eq: $url }, node_locale: { eq: $node_locale }) {
      createdAt
      updatedAt
      title
      url
      category
      location
      internalPosition
      responsibilitiesHeadline
      jobOfferResponsibilities {
        responsibilityDescription {
          responsibilityDescription
        }
      }
      specificationsHeadline
      yourSpecifications {
        specificationLong {
          specificationLong
        }
        specificationType
      }
      employmentType
      outro {
        headline
        text {
          json
        }
        stopper {
          text {
            json
          }
        }
      }
      customQuestions {
        question {
          question
        }
      }
      jobDescription {
        json
      }
      internalJobId
    }
    allContentfulWhatWeOffer(filter: { node_locale: { eq: $node_locale } }) {
      nodes {
        headline
        specificationType
        imageId
        description {
          description
        }
        category
        location
        employmentType
        contentfulid
      }
    }
  }
`;
